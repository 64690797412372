import React from 'react';
import { Link } from 'react-router-dom';
import product1 from "../Assets/wasgung.png";
import heatermain from "../Assets/Heater/heatermain.jpg";
import grinderImg from "../Assets/Grinder/grinderImg1.png";
import pumpImg from "../Assets/Pump/pumpImg.png";

const ProductPage = () => {
  const products = [
    {
      id: 1,
      product: 'WashingMachine',
      name: 'Washing Machine',
      description: 'Efficient washing machine with multiple options.',
      price: '',
      image: product1,
    },
    {
      id: 2,
      product: 'WaterHeater',
      name: 'Water Heater',
      description: 'Durable heater perfect for home use.',
      price: '',
      image: heatermain,
    },
    {
      id: 3,
      product: 'Grinder',
      name: 'Grinder',
      description: 'Reliable grinder for various applications.',
      price: '',
      image: grinderImg,
    },
    {
      id: 4,
      product: 'Pump',
      name: 'Pump',
      description: 'Energy-efficient pump with ample performance.',
      price: '',
      image: pumpImg,
    },
    {
      id: 5,
      product: 'Microwave',
      name: 'Microwave',
      description: 'Compact microwave with multiple cooking modes.',
      price: '',
      image: 'https://via.placeholder.com/150',
    },
    {
      id: 6,
      product: 'Air Conditioner',
      name: 'Air Conditioner',
      description: 'Cooling solution with energy-saving technology.',
      price: '',
      image: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <div className="container mt-2 pb-5">
      <h2 className="appliance-header">
        Our Products
      </h2>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 ">
        {products.map((product, index) => (
          index < products.length - 2 ? ( // Show regular products
            <div className="col" key={product.id}>
              <div className="product-card border-0 hover-card bg-white h-100"
                style={{
                  borderRadius: "1rem",
                  overflow: "hidden",
                  backgroundColor: "#fff",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                }}
              >

                {/* Image Section */}
                <div className="w-100" style={{ height: "250px" }}>
                  <img
                    src={product.image}
                    alt={product.name}
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      borderRadius: "0.5rem",
                      transition: "transform 0.3s",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                    onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                  />
                </div>

                {/* Card Body */}
                <div className="card-body text-center d-flex flex-column p-3">
                  <h5
                    className="product-name card-title mb-2"
                    style={{ fontSize: "1.1rem", fontWeight: "600", textShadow: "1px 1px 2px rgba(0,0,0,0.1)" }}
                  >
                    {product.name}
                  </h5>
                  <p
                    className="product-description card-text flex-grow-1"
                    style={{
                      color: "#666",
                      fontSize: "0.9rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {product.description}
                  </p>
                </div>

                {/* Card Footer */}
                <div className="card-footer d-flex justify-content-between align-items-center p-3 h-auto" style={{ backgroundColor: "#f9f9f9" }}>
                  <h6
                    className="product-price mb-0"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      color: "#ff4b2b",
                    }}
                  >
                    {product.price}
                  </h6>
                  <Link
                    to={`/${product.product}`}
                    className="btn btn-primary buy-button"
                    style={{
                      background: "linear-gradient(45deg, #ff4b2b, #ff416c)",
                      border: "none",
                      padding: "0.6rem 1rem",
                      borderRadius: "0.5rem",
                      fontSize: "0.85rem",
                      transition: "background 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.background = "linear-gradient(45deg, #ff416c, #ff4b2b)")}
                    onMouseOut={(e) => (e.currentTarget.style.background = "linear-gradient(45deg, #ff4b2b, #ff416c)")}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ) : ( // Show Coming Soon for the last two products
            <div className="col" key={product.id}>
              <div className="product-card border-0 bg-light h-100"
                style={{
                  borderRadius: "1rem",
                  overflow: "hidden",
                  backgroundColor: "#e0e0e0",
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  pointerEvents: 'none', // Disable interactions
                }}
              >
                <h5 className="coming-soon" style={{ fontSize: '1.5rem', color: '#ff4b2b' }}>
                  Coming Soon
                </h5>
                <p className="text-muted" style={{ fontSize: '1rem' }}>
                  Stay tuned for updates!
                </p>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
